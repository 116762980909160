import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./JoinUsOurHeros.module.scss";

function JoinUsOurHeros() {
  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 2,
      slideBy: 2,
    },
    992: {
      items: 3,
      slideBy: 3,
    },
    1200: {
      items: 3,
      slideBy: 3,
    },
  };
  return (
    <section
      className={`${styles["section"]} ${styles["section__perspective"]} ${styles["__section_medium_tb_padding"]} --section-medium-tb-padding `}
    >
      <div className={`${styles["container"]}`}>
        <div className={`${styles["container__row"]}`}>
          <div
            className={`${styles["container__col_xs_12"]} ${styles["__center"]}`}
          >
            <h2 className={`${styles["container__heading"]}`}>
              Hear from our Superheroes
            </h2>
            <div className={`${styles["container__row"]}`}>
              <div
                className={`${styles["container__col_md_8"]} ${styles["container__col_md_offset_2"]}`}
              >
                <h6 className={`${styles["container__content"]}`}>
                  In our pursuit to build a world-class product and company, we
                  have onboarded some of the best talent in the industry.
                  Curious about what's it like to work at Hashout? Hear from our
                  superheroes themselves.
                </h6>
              </div>
            </div>
          </div>

          {typeof window !== "undefined" && OwlCarousel && (
            <OwlCarousel
              className={`${styles["owl_theme"]} ${styles["our_perspective_carousel"]}`}
              margin={20}
              autoplay={false}
              autoplayTimeout={6000}
              autoplaySpeed={1000}
              autoplayHoverPause={true}
              dots={false}
              nav={false}
              stagePadding={35}
              responsive={carouselResponsive}
            >
              <div className={`${styles["owl_carousel__item"]}`}>
                <div className={`${styles["card"]} ${styles[""]}`}>
                  <img
                    className={`${styles["card__image"]}`}
                    src="../../solutions/join-us/superheroes/akshay-rathnavas.png"
                    alt="HASHOUT"
                  />
                  <div className={`${styles["card__content"]}`}>
                    <h4 className={`${styles["card__title"]}`}>
                      Akshay Rathnavas
                    </h4>
                    <p className={`${styles["card__description"]}`}>
                      Before he joined Hashout Tech, Akshay Rathnavas wasn’t
                      growing his career as quickly as he wanted and ....
                    </p>
                    <a
                      className={`${styles["card__action"]} ${styles["__action_link"]}`}
                      href="../../Hashout-Tech-Employee-testimonial.pdf"
                      target="_blank"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <div className={`${styles["card"]}`}>
                  <img
                    className={`${styles["card__image"]}`}
                    src="../../solutions/join-us/superheroes/priyanka-sarawagi.png"
                    alt="HASHOUT"
                  />
                  <div className={`${styles["card__content"]}`}>
                    <h4 className={`${styles["card__title"]}`}>
                      Priyanka Sarawagi
                    </h4>
                    <p className={`${styles["card__description"]}`}>
                      I was heard and trusted from Day 1 and I am grateful for
                      all the support for my professional development and
                      upcoming opportunity.
                    </p>
                    {/* <a
                      className={`${styles["card__action"]} ${styles["__action_link"]}`}
                      href=""
                    >
                      Read more
                    </a> */}
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <div className={`${styles["card"]}`}>
                  <img
                    className={`${styles["card__image"]}`}
                    src="../../solutions/join-us/superheroes/abhilash-behera.png"
                    alt="HASHOUT"
                  />
                  <div className={`${styles["card__content"]}`}>
                    <h4 className={`${styles["card__title"]}`}>
                      Abhilash Behera
                    </h4>
                    <p className={`${styles["card__description"]}`}>
                      I love being a part of the community and being able to
                      work with so many different people. Everyone is so
                      friendly and there is always something fun going on.
                    </p>
                    {/* <a
                      className={`${styles["card__action"]} ${styles["__action_link"]}`}
                      href=""
                    >
                      Read more
                    </a> */}
                  </div>
                </div>
              </div>
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
}

export default JoinUsOurHeros;
