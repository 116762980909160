import React, { useEffect } from "react";

function JoinUsOpenPositions() {
  useEffect(() => {
    $(function () {
      $(".join-us-accordion ul li > a:not(:only-child)")
        .off("click")
        .on("click", function (e) {
          $(this).siblings(".nav-dropdown").slideToggle();
          $(".nav-dropdown").not($(this).siblings()).slideUp();
          e.stopPropagation();
        });
    });
  });
  return (
    <section className="section section--our-positions section--positions section--tools-left-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-4">
            <h2 className="container__heading">Open Positions</h2>
            <p className="container__content">
              We pride ourselves on hiring the best and empowering them to
              fulfill their potential. This means giving each team member full
              ownership of their work and the resources they need to accomplish
              their goals.
            </p>
          </div>
          <div className="container__col-md-8">
            <div className="container__row">
              <div className="container__col-xs-12">
                <nav className="nav join-us-accordion">
                  <ul className="nav-list">
                    <li className="nav-list__list">
                      <a className="nav-list__link" href="javascript:void(0);">
                        <div className="nav-list__heading">
                          AEM Solution Architect
                        </div>
                        <div className="nav-list__sub-heading">
                          Remote (Preference- Bengaluru)
                        </div>
                      </a>
                      <ul className="nav-dropdown nav-dropdown--active">
                        <li className="nav-dropdown__list">
                          <div className="nav-dropdown__content">
                            We are in search of a proficient and seasoned AEM Solution Architect to join our growing team
dedicated to the Adobe Experience Cloud, which assists several top enterprises worldwide in the
design and delivery of highly engaging customer experiences.
                          </div>
                          <p className="h7 nav-dropdown__heading">Ideal Candidate:</p>
                          <div className="nav-dropdown__content">
                            <ul style={{listStylePosition: "inside"}}>
                              <li>10+ years of overall and 6+ years of AEM</li>
                              <li>Adobe Certified Expert on AEM</li>
                              <li>Currently Lead / Solution Architect managing multiple projects in an organization</li>
                            </ul>
                          </div>
                          <div className="jd-link-wrapper">
                          <a
                              className="card__action --action-link"
                              href= "https://www.linkedin.com/jobs/view/3843650718/"
                              target="_blank"
                            >
                              Apply on LinkedIn
                            </a>
                            <a
                              className="card__action --action-link --download-icon"
                              href="../../jd/AEM_Solution_Architect.pdf"
                              target="_blank"
                            >
                              Job Description
                            </a>
                            
                          </div>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="nav-list__list">
                      <a className="nav-list__link" href="javascript:void(0);">
                        <div className="nav-list__heading">
                          Fullstack Engineer (Java Developer)
                        </div>
                        <div className="nav-list__sub-heading">BANGALORE, KA</div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <h7 className="nav-dropdown__heading">Description:</h7>
                          <div className="nav-dropdown__content">
                            Experienced AEM developers and architects with
                            proficiency in Java for server-side development and
                            hands-on coding experience in AEM, HTML, CSS, and
                            JavaScript front-end development.
                          </div>
                          <a className="card__action --action-link" href="">
                            Apply in LinkedIn
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-list__list">
                      <a className="nav-list__link" href="javascript:void(0);">
                        <div className="nav-list__heading">
                          Full Stack Delivery Manager
                        </div>
                        <div className="nav-list__sub-heading">BANGALORE, KA</div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <h7 className="nav-dropdown__heading">Description:</h7>
                          <div className="nav-dropdown__content">
                            Experienced AEM developers and architects with
                            proficiency in Java for server-side development and
                            hands-on coding experience in AEM, HTML, CSS, and
                            JavaScript front-end development.
                          </div>
                          <a className="card__action --action-link" href="">
                            Apply in LinkedIn
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-list__list">
                      <a className="nav-list__link" href="javascript:void(0);">
                        <div className="nav-list__heading">
                          Sr Frontend Engineer (React JS/Angular)
                        </div>
                        <div className="nav-list__sub-heading">BANGALORE, KA</div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <h7 className="nav-dropdown__heading">Description:</h7>
                          <div className="nav-dropdown__content">
                            Experienced AEM developers and architects with
                            proficiency in Java for server-side development and
                            hands-on coding experience in AEM, HTML, CSS, and
                            JavaScript front-end development.
                          </div>
                          <a className="card__action --action-link" href="">
                            Apply in LinkedIn
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-list__list">
                      <a className="nav-list__link" href="javascript:void(0);">
                        <div className="nav-list__heading">
                          Technical Project Manager
                        </div>
                        <div className="nav-list__sub-heading">BANGALORE, KA</div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <h7 className="nav-dropdown__heading">Description:</h7>
                          <div className="nav-dropdown__content">
                            Experienced AEM developers and architects with
                            proficiency in Java for server-side development and
                            hands-on coding experience in AEM, HTML, CSS, and
                            JavaScript front-end development.
                          </div>
                          <a className="card__action --action-link" href="">
                            Apply in LinkedIn
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li className="nav-list__list">
                      <a className="nav-list__link">
                        <div className="nav-list__heading">
                          Sr. AEM Developer
                        </div>
                        <div className="nav-list__sub-heading">
                          Remote (Preference- Bengaluru)
                        </div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <p className="nav-dropdown__heading h7">
                            Description:
                          </p>
                          <div className="nav-dropdown__content">
                            We are seeking a skilled AEM Developer to join our development team and contribute to the design, development, and maintenance of Adobe Experience Manager (AEM) solutions.
                          </div>
                          <div className="jd-link-wrapper">
                          <a
                              className="card__action --action-link"
                              href= "https://www.linkedin.com/jobs/view/3899010990/"
                              target="_blank"
                            >
                              Apply on LinkedIn
                            </a>
                            <a
                              className="card__action --action-link --download-icon"
                              href="../../jd/AEM Developer_Sr AEM Developer.pdf"
                              target="_blank"
                            >
                              Job Description
                            </a>
                            
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-list__list">
                      <a className="nav-list__link">
                        <div className="nav-list__heading">
                          Sr Fullstack Developer (Java/J2EE)
                        </div>
                        <div className="nav-list__sub-heading">
                          Remote (Preference- Bengaluru)
                        </div>
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown__list">
                          <p className="nav-dropdown__heading h7">
                            Description:
                          </p>
                          <div className="nav-dropdown__content">
                            We are looking for passionate programmers, thinkers and problem solvers. You should be hands on with coding, software design and building scalable applications. We look forward to you joining us on this exciting journey.
                          </div>
                          <div className="jd-link-wrapper">
                          <a
                              className="card__action --action-link"
                              href= "https://www.linkedin.com/jobs/view/3899032266/"
                              target="_blank"
                            >
                              Apply on LinkedIn
                            </a>
                            
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUsOpenPositions;
