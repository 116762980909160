import React from "react";

function JoinUsBenefits() {
  return (
    <section className="section section--tools section--benefits-perks section--bg-gray-lightest section--waves-bg --section-large-tb-padding">
      <div className="container ">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Benefits and Perks</h2>
            <div className="container__content">
              We offer the best packages in the industry topped with an
              environment to learn and grow.
            </div>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/employee-first.png"
                alt="Employee First"
              />
              <a>
                <h5 className="card__title">Employee First</h5>
              </a>
              <p className="card__description">
                We believe in freedom and accontability. All our policies are
                employee friendly,
              </p>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/meritocracy.png"
                alt="Meritocracy "
              />
              <a>
                <h5 className="card__title">Meritocracy </h5>
              </a>
              <p className="card__description">
                We reward transparently! Your work speaks for you!
              </p>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/inclusive-diverse.png"
                alt="Inclusive and Diverse"
              />
              <a>
                <h5 className="card__title">Inclusive and Diverse</h5>
              </a>
              <p className="card__description">
                We are and equal opportunity provider and proud to be
                discrimination free and inclusive organisation
              </p>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/group-health-insurance.png"
                alt="Group Health Insurance"
              />
              <a>
                <h5 className="card__title">Group Health Insurance</h5>
              </a>
              <p className="card__description">
                Medical insurance also covers Spouse and 2 children.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/bring-your-own-device.png"
                alt="Bring Your Own Device"
              />
              <a>
                <h5 className="card__title">Bring Your Own Device</h5>
              </a>
              <p className="card__description">
                Choose a device of your choice or bring your favourite device
                onboard and opt in for support.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/earned-leaves.png"
                alt="Earned Leaves"
              />
              <a>
                <h5 className="card__title">Earned Leaves</h5>
              </a>
              <p className="card__description">
                We work 5 days a week. you will receive 22 paid holidays each
                calender year on a pro rata-basis, along with public holidays.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/join-us/benefits/fun-place-work.png"
                alt="Fun place to work"
              />
              <a>
                <h5 className="card__title">Fun place to work</h5>
              </a>
              <p className="card__description">
                Work hard, party hard and share passions together with regular
                onsite, virtual and offsite pit stops.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUsBenefits;
