import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./JoinUsDiscover.module.scss";

function JoinUsDiscover() {
  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 1,
      slideBy: 1,
    },
    992: {
      items: 1,
      slideBy: 1,
    },
    1200: {
      items: 1,
      slideBy: 1,
    },
  };
  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
  };
  return (
    <section
      className={`${styles["section"]} ${styles["section__projects"]}  --bg-gray-lightest section--overflow-hidden --section-mlarge-tb-padding`}
    >
      <div className={`${styles["container"]}`}>
        <div className={`${styles["container__row"]}`}>
          <div
            className={`${styles["container__col_md_6"]} ${styles["container__col_xs_12"]}
          ${styles["container__testimonial"]}`}
          >
            <h2 className={`${styles["container__heading"]}`}>
              Discover your calling
            </h2>

            {typeof window !== "undefined" && OwlCarousel && (
              <OwlCarousel
                className={`${styles["owl_theme"]} ${styles["testimonial_carousel"]} ${styles["owl_carousel"]}`}
                // loop= "loop"
                margin={50}
                autoplay="true"
                autoplayTimeout="6000"
                autoplaySpeed="1000"
                autoplayHoverPause="true"
                nav="true"
                // navText= ['', '']
                dots="true"
                stagePadding="10"
                responsive={carouselResponsive}
                onInitialized={counter}
                onTranslated={counter}
              >
                <div className={`${styles["owl_carousel__item"]} `}>
                  <div className={`${styles["testimonial"]} `}>
                    <div className={`${styles["testimonial__content"]} `}>
                      “I have grown so much in my career at Hashout—and achieved
                      a level of success I didn’t think was possible in such a
                      short time. It’s rewarding to get to a point where you
                      know you’re one of the best in your field.”
                    </div>
                    <div className={`${styles["testimonial__name"]} `}>
                      Akshay Rathnavas
                    </div>
                    <div className={`${styles["testimonial__location"]} `}>
                      Bangalore
                    </div>
                  </div>
                </div>
                {/* <div className={`${styles["owl_carousel__item"]} `}>
									<div className={`${styles["testimonial"]} `}>
										<div className={`${styles["testimonial__content"]} `}>
											“I have grown so much in my career at Hashout—and achieved
											a level of success I didn’t think was possible in such a
											short time. It’s rewarding to get to a point where you
											know you’re one of the best in your field.”
										</div>
										<div className={`${styles["testimonial__name"]} `}>
											Priyanka Sarawagi
										</div>
										<div className={`${styles["testimonial__location"]} `}>
											Bangalore
										</div>
									</div>
								</div>
								<div className={`${styles["owl_carousel__item"]} `}>
									<div className={`${styles["testimonial"]} `}>
										<div className={`${styles["testimonial__content"]} `}>
											“I have grown so much in my career at Hashout—and achieved
											a level of success I didn’t think was possible in such a
											short time. It’s rewarding to get to a point where you
											know you’re one of the best in your field.”
										</div>
										<div className={`${styles["testimonial__name"]} `}>
											Nilabjo Sanyal
										</div>
										<div className={`${styles["testimonial__location"]} `}>
											Bangalore
										</div>
									</div>
								</div> */}
              </OwlCarousel>
            )}
          </div>
          <div
            className={`${styles["container__col_md_6"]} --flex-center ${styles["container__col_xs_12"]} `}
          >
            <a href="https://app.frame.io/presentations/ad7bf209-077b-4bde-9441-5d3ceb35b0a1">
              <img
                className={`--bg-absolute-right --testimonial-video-link`}
                src="../../solutions/join-us/discover-your-call.png"
                alt="discover-your-call"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUsDiscover;
