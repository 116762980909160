import React from "react";

function JoinUsBannerImg() {
  return (
    <section className="section section--img-absolute --section-large-tb-padding --section-large-tb-padding-pad-bottom">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <img
              className="container__image"
              src="../../solutions/join-us/join-the-team.png"
              alt="HASHOUT"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUsBannerImg;
