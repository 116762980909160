import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./JoinUsCareer.module.scss";

function JoinUsCareer() {
  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 2,
      slideBy: 2,
    },
    992: {
      items: 3,
      slideBy: 3,
    },
    1200: {
      items: 3,
      slideBy: 3,
    },
  };
  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
  };
  return (
    <section
      className={`${styles["section"]} ${styles["section__projects"]}  ${styles["section__pad_top_0"]} ${styles["__section_large_tb_padding"]}`}
    >
      <div className={`${styles["container"]}`}>
        <div className={`${styles["container__row"]}`}>
          <div className={`${styles["container__col_xs_12"]}`}>
            <h2 className={`${styles["container__heading"]}`}>
              Career at Hashout
            </h2>
            <div className={`${styles["container__row"]}`}>
              <div className={`${styles["container__col_md_7"]}`}>
                <div>
                  We pride ourselves on hiring the best and empowering them to
                  fulfill their potential. This means giving each team member
                  full ownership of their work and the resources they need to
                  accomplish their goals.
                </div>
              </div>
            </div>
          </div>
          {typeof window !== "undefined" && OwlCarousel && (
            <OwlCarousel
              className={`${styles["owl_theme"]} ${styles["our_projects_carousel"]} ${styles["owl_carousel"]}`}
              margin={30}
              autoplay={true}
              autoplayTimeout={6000}
              autoplaySpeed={1000}
              autoplayHoverPause={true}
              nav={true}
              dots={true}
              stagePadding={35}
              responsive={carouselResponsive}
              onInitialized={counter}
              onTranslated={counter}
            >
              <div className={`${styles["owl_carousel__item"]} `}>
                <img
                  className={`${styles["owl_carousel__image"]} `}
                  src="../../solutions/join-us/career/learning-lifetime.png"
                  alt="Learning of lifetime"
                />
                <div className={`${styles["owl_carousel__content"]} `}>
                  <a className={`${styles["owl_carousel__link"]} `}>
                    <h5 className={`${styles["owl_carousel__heading"]} `}>
                      Learning of lifetime
                    </h5>
                  </a>
                  <div>
                    Our teams constantly expand the boundaries to deliver more
                    with less. With our continuous focus on learning, no two
                    year will be the same for you.
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]} `}>
                <img
                  className={`${styles["owl_carousel__image"]} `}
                  src="../../solutions/join-us/career/best-latest.png"
                  alt="Work with the best and the latest"
                />
                <div className={`${styles["owl_carousel__content"]} `}>
                  <a className={`${styles["owl_carousel__link"]} `}>
                    <h5 className={`${styles["owl_carousel__heading"]} `}>
                      Work with the best and the latest
                    </h5>
                  </a>
                  <div>
                    Our carefully chosen technology stack and partnerships keep
                    us right at the intersection between cutting edge technology
                    and demand. Come and pioneer the change with us!
                  </div>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]} `}>
                <img
                  className={`${styles["owl_carousel__image"]} `}
                  src="../../solutions/join-us/career/fortune-clients.png"
                  alt="Work with fortune 500 clients"
                />
                <div className={`${styles["owl_carousel__content"]} `}>
                  <a className={`${styles["owl_carousel__link"]} `}>
                    <h5 className={`${styles["owl_carousel__heading"]} `}>
                      Work with fortune 500 clients
                    </h5>
                  </a>
                  <div>
                    We have successfully delivered customer experience platforms
                    for top brands across industries. With us - You will shape
                    some of the most impactful business and mission critical
                    experiences for global enterprises.
                  </div>
                </div>
              </div>
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
}

export default JoinUsCareer;
