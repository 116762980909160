import React from "react";

function JoinUsBanner() {
  return (
    <section className="section section--banner section--gray-bg section--stripes-waves-bg section--joinus-pad-bottom">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-12 --section-medium-tb-padding --center">
            <h1 className="container__heading">Join the Team </h1>
            <h6 className="container__content">
              We are a product engineering company focused on creating amazing
              digital experiences for enterprises worldwide.
              <br />
              We’re growing rapidly and are always on the lookout for great
              talent.
            </h6>
            <a className="container__clickable" href="#view_all_opening">
              <button className="container__button">VIEW ALL OPENINGS</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUsBanner;
